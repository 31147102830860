import axios from "axios";
import { jwtDecode } from "jwt-decode";

// Additional export for the API PDF URL
//const urlPdf = "https://dev.api.marion-materiaux.com"; // Dev
//const urlPdf = "https://recette.api.marion-materiaux.com"; // Recette
const urlPdf = "https://api.marion-materiaux.com"; // Prod

const instance = axios.create({
 //baseURL: "https://dev.api.marion-materiaux.com/api", // Dev
 //baseURL: "https://recette.api.marion-materiaux.com/api", // Recette
  baseURL: 'https://api.marion-materiaux.com/api', // Prod
});

const authToken = localStorage.getItem("authToken");
const parsedUserData = JSON.parse(localStorage.getItem("userData"));

function checkTokenAndRedirect(authToken) {
  // Get the current pathname
  const currentPath = window.location.pathname;
  const allowedPaths = [
    "/login",
    "/reinitialiser_mot_de_passe",
    "/changer_mot_de_passe",
    "/fournisseur/renitialise-mot-de-passe",
    "/fournisseur/commande",
  ];
  //const isAllowedPath = allowedPaths.includes(currentPath);
  const isAllowedPath = allowedPaths.some((allowedPath) =>
    currentPath.startsWith(allowedPath)
  );
  if (!isTokenValid(authToken)) {
    if (!isAllowedPath) {
      window.location.href = "/login";
    }
    return; // Stop further execution
  }

  if (!isAllowedPath && parsedUserData.role == 6) {
    window.location.href = "/fournisseur/commande";
  }
}

function isTokenValid(token) {
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp < Date.now() / 1000) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}

checkTokenAndRedirect(authToken);

instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { urlPdf };

export default instance;
